<template>
    <div class="cs-hero-block cs-block" :class="styleClass">
        <div class="cs-block-base">
            <div class="cs-block-background background" :style="backgroundImage"></div>
            <div class="content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7">
                            <h1 v-if="data.title" class="cs-title">{{ data.title }}</h1>
                            <h3 v-if="data.subTitle" class="cs-sub-title">{{data.subTitle}}</h3>
                            <span v-if="data.text" class="cs-text" v-html="data.text"></span>
                            <div class="btns" v-if="data.buttons && Object.keys(data.buttons).length">
                                <Buttons :data="data.buttons" :cs_style="data.style" />    
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <!-- <CSMedia :data="data.image" :support="['image', 'video']" /> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {computed} from '../libs/common-fn';

    export default {
        name: "HeroSection",
        components: {
            Buttons: () => import("../components/CSUtilsButtons.vue"),
            CSMedia: () => import("../components/CSMedia.vue"),
        },
        props: {
            data: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {};
        },
        computed: computed('HeroSection')
    };
</script>

<style lang="scss" scoped>
    @import "../styles/main.scss";


    .cs-block.cs-hero-block{
        .cs-block-base{
            .background {
                @media (max-width: 991px) {
                    background-position: center;
                }
                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: #000;
                    opacity: 0.3;
                    z-index: 1;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            ::v-deep .cs-media {
                display: block;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                video {
                    width: 100%;
                    height: 100%;
                }
            }
            .content {
                position: relative;
                z-index: 2;
                // @include block-pt;
                // @include block-pb;
                h1.cs-title {
                    font-size: 50px;
                    font-weight: 400;
                    margin: 0 auto 20px;
                    color: #fff;
                    @media (max-width: 575px) {
                        font-size: 36px;
                    }
                }
                h3.cs-sub-title {
                    margin: 0 auto 20px;
                    color: $theme_color !important;
                }
                span.cs-text:not(.cs-media) {
                    margin-bottom: 20px;
                    color: #fff;
                    ::v-deep *{
                        color: #fff !important;
                    }
                }
                .btns {
                    @media (max-width: 991px) {
                        margin-bottom: 40px;
                        margin-top: -20px;
                    }
                    ::v-deep {
                        .button-group {
                            .cs-button {
                                color: #fff !important;
                                &:hover {
                                    color: #fff !important;
                                }
                            }
                        }
                        .button-group:nth-child(even) .cs-button {
                            color: #fff !important;
                            &:hover {
                                color: #fff !important;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
